<template>
  <div class="myBankCard">
    <div class="go-back">
      <div @click="onClickLeft">
        <van-icon name="arrow-left" class="icon-left"/>
        <span>我的银行卡</span>
      </div>
    </div>
    <div class="content" v-if="bankCardList">
      <div v-if='bankCardList.length == 0' style="display:flex; flex-direction:column; align-items:center;">
        <van-empty description="暂未绑卡" style="padding-bottom:0;" />
        <div class="bind-button" @click="bindCard">立即绑定</div>
      </div>

      <template v-else>
        <div class="card" v-for="item in bankCardList" :key="item.card_id">
          <div class="back-logo">
            <img :src="item.bank_logo" alt="">
          </div>
          <div class="back-info">
            <div class="card-name">
              <div>{{item.bank_name}}</div>
              <div class="card-type">借记卡号</div>
            </div>
            <div class="back-number">{{item.bank_card_no}}</div>
          </div>
        </div>
        <div class="change-card" @click="getChangeCard">更换银行卡</div>
      </template>
    </div>
  </div>
</template>

<script> 
import {getCardList} from '@/api/bank.js'
export default {
  name: 'myBankCard',

  data() {
    return {
      serchForm:{
        page:1,
        pageSize:20
      },
      bankCardList: null
    };
  },

  mounted() {
    this.initData()
  },

  methods: {
     async initData(){
      const {code,data} = await getCardList(this.serchForm)
      if(code == 200){
        this.bankCardList = data.list || []
      }
    },
    getChangeCard(){
      this.$router.push({
        path:'/changeBankCard',
      })
    },
    bindCard(){
      this.$router.push({
        path:'/changeBankCard',
        query: {
          type: 'bind'
        }
      })
    },
    onClickLeft(){
      this.$router.push('/my')
    },
  },
};
</script>

<style lang="scss" scoped>
.myBankCard{
  height: 100%;   
  width: 100%;
  background: #f5f5f5;
  .go-back{
    display: flex;
    align-items: center;
    padding-left: 20px;
    font-size: 30px;
    width: 100%;
    background: #fff;
    border-top: 1px solid #E7E7E7;
    border-bottom: 1px solid #E7E7E7;
    height: 100px;
  }
  .content{
    padding: 30px;
    .card{
      margin-bottom: 30px;
      color: #fff;
      font-size: 30px;
      padding: 30px;
      background: #1677FF;
      box-shadow: 0px 7px 13px 0px rgba(22,119,255,0.32);
      border-radius: 20px;
      display: flex;
      .back-logo{
        height: 80px;
        width: 80px;
        border-radius: 50%;
        background: #fff;
        margin-right: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          height: 70px;
          width: 70px;
          border-radius: 50%;
        }
      }
      .back-info{
        .card-name{
          .card-type{
            font-size: 24px;
            margin-top: 10px;
            margin-bottom: 30px;
          }
        }
      }
    }
    .bind-button{
      width: 40%;
      text-align: center;
      line-height: 70px;
      padding: 0px 45px;
      background: #1677ff;
      font-size: 20px;
      color: #fff;
      box-shadow: 0px 7px 13px 0px rgba(22, 119, 255, 0.32);
      border-radius: 50px;
    }
    .change-card{
      font-size: 28px;
      float: right;
      text-decoration: underline;
      color: #1677FF;
      margin: 10px 20px;
    }
  }
}
</style>